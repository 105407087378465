$color-bg-primary: #333; //#6c63fa;
$color-bg-light: #fff; // #ececed;
$color-bg-dark: #47484f;
$color-text-light-primary: #fff; // #ececed;
$color-text-light-hover: #ddd;
$color-text-dark-primary: #47484f;
$color-text-dark-secondary: #656670;

$sm: 481px;
$md: 769px;
$lg: 1025px;

$font-size: 17px;
$font-size-sm: 15px;

$header-font: "Open Sans", sans-serif;
$h1-font-weight: 800;
$h2-font-weight: 700;
$h3-font-weight: 600;
$header-line-height: 1.4;
$body-font: "Nunito", sans-serif;
$body-font-weight: 600;
$body-line-height: 1;
$box-shadow: 0px 0px 18px 5px rgba(0, 0, 0, 0.3);
$card-border-radius: 6px;

$btn-color-primary: #fa6863;
$btn-font-family: "Open Sans", sans-serif;
$btn-font-weight: 700;
$btn-font-size: 0.9rem;
$btn-border-radius: 4px;

$tag-color-primary: #ae2eff;
$tag-font-family: "Open Sans", sans-serif;
$tag-font-weight: 600;

$animated-background-cutoff: 450px;

$project-preview-width: 422px;
