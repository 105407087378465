@import "variables.scss";

article.project-card {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: 100%;
  max-width: $project-preview-width;
  margin: 0;

  padding: 0 0.5em 0.5em 0.5em;
  background-color: $color-bg-light;
  border-style: solid;
  border-width: 0px;
  border-radius: $card-border-radius;
  overflow: hidden;
  box-shadow: $box-shadow;

  * {
    margin: 0;
  }

  .project-card-content {
    margin-top: 0.7em;
    flex-grow: 1;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    p {
      color: $color-text-dark-secondary;
    }

    .top-aligned {
      .project-card-header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        h2 {
          margin-right: 10px;
        }

        .project-card-links {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
          & > * {
            margin-right: 0.5em;
          }
          :last-child {
            margin-right: 0;
          }
        }
      }

      .project-card-body {
        margin-top: 0.85em;
      }
    }

    .bottom-aligned.project-card-tags {
      margin-top: 0.75em;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: center;
      & > * {
        margin: 0;
        margin-right: 0.5em;
        margin-top: 0.35em;
      }
      :last-child {
        margin-right: 0;
      }
    }
  }

  a {
    flex-shrink: 0;
    align-self: center;
    img.project-card-img {
      object-fit: cover;
      height: 240px;
      width: $project-preview-width;
    }
  }
}
